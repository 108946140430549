import { AxiosInstance } from "axios";

interface TokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
}

type AuthenticateProps =
  | { grant_type: "password"; username: string; password: string }
  | { grant_type: "token_exchange"; subject_token: string };

export const authenticate =
  (client: AxiosInstance) => async (auth: AuthenticateProps) => {
    const { status, data } = await client.post<TokenResponse>("/token", auth);
    if (status === 200) {
      return data;
    }
  };
