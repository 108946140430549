import { useSearchParams } from "react-router-dom";
import { getBorder } from "@coachhubio/nova-borders";
import { Button } from "@coachhubio/nova-button";
import { getBackgroundColor } from "@coachhubio/nova-colors";
import { padding } from "@coachhubio/nova-spaces";
import { getTextStyle } from "@coachhubio/nova-typography";
import styled from "styled-components";
import YouTube, { YouTubePlayer } from "react-youtube";
import { useCallback, useEffect, useMemo, useState } from "react";
import useAuth from "hooks/useAuth";
import { UserRoles } from "context/AuthContext";
import { Container, Grid, Title } from "components/Common";
import { LoadingState } from "components/LoadingState";
import useEventService from "hooks/useEventService";
import { EventWithTimeline } from "services/events.service";
import { toTimeString } from "utils/time";

const VideoContainer = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    height: 100%;
    max-height: 75vh;
    left: 0;
    position: absolute;
    tops: 0;
    width: 100%;
    border: none;
  }
`;

const ShareButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
`;

const Timeline = styled.div`
  ${getBorder({ color: "neutral80", width: "m", radius: "m" })}
`;

const Wrapper = styled.div`
  ${padding("l")}
  max-height: 75vh;
  overflow-y: scroll;
  scrollbar-color: rebeccapurple green;
  scrollbar-width: thin;
  & > :not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const Comment = styled.div`
  display: grid;
  grid-template-columns: 90px 1fr;
  gap: 0.5rem;
  padding: 0.5rem;
  ${getBorder({ color: "neutral80", width: "m", radius: "m" })}
  box-shadow: 0 5px 7px -1px rgba(51, 51, 51, 0.23);
  cursor: pointer;
  transition: all 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
  ${getBackgroundColor("neutral")}
  overflow: hidden;
  &:hover {
    transform: scale(1.05);
    ${getBackgroundColor("neutral85")}
    box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);
  }
  .second {
    ${getTextStyle("text", "base", "bold")}
    text-align: center;
    position: relative;
    margin: 0 0.5rem;
    &:before,
    &:after {
      position: absolute;
      display: block;
      width: 0.5rem;
      top: 0;
      bottom: 0;
    }
    &:before {
      content: "[";
      left: -0.5rem;
    }
    &:after {
      content: "]";
      right: -0.5rem;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export default function Recording() {
  const { user } = useAuth();
  const { getTimeline, shareVideo } = useEventService();
  const [timeline, setTimeline] = useState<EventWithTimeline>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [player, setPlayer] = useState<YouTubePlayer>();
  const [searchParams] = useSearchParams();
  const [refetch, setRefetch] = useState(0);

  const [eventId, coacheeId] = useMemo(() => {
    const eventId = searchParams.has("event_id")
      ? Number(searchParams.get("event_id"))
      : null;
    const coacheeId = searchParams.get("coachee_id");
    return [eventId, coacheeId];
  }, [searchParams]);

  useEffect(() => {
    // used to prevent calling setting function on unmounted component
    let stale = false;
    async function fetchTimeline(eventId: number, coachedId: string) {
      setIsLoading(true);
      setError(false);
      try {
        const timeline = await getTimeline(eventId, coachedId);
        if (!stale) {
          setTimeline(timeline);
        }
      } catch (err) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    }
    if (eventId === null || coacheeId === null) {
      return;
    }
    fetchTimeline(eventId, coacheeId);
    return () => {
      stale = true;
    };
  }, [eventId, coacheeId, refetch, getTimeline]);

  const handleShare = useCallback(async () => {
    if (!eventId || !coacheeId) return;
    setIsSharing(true);
    try {
      await shareVideo(eventId, coacheeId);
      setRefetch(refetch + 1);
    } catch (err) {
      console.error("Failed to share", err);
    } finally {
      setIsSharing(false);
    }
  }, [eventId, coacheeId, refetch, shareVideo]);

  const isCoach = useMemo(() => {
    return user?.roles.includes(UserRoles.Coach);
  }, [user]);

  const videoId = useMemo(() => {
    if (!timeline?.video) {
      return;
    }
    const url = new URL(timeline.video);
    const params = new URLSearchParams(url.search);
    if (params.has("v")) return params.get("v");
    return url.pathname.split("/").slice(-1)[0];
  }, [timeline]);

  if (isLoading) {
    return <LoadingState />;
  }

  if (error) {
    return (
      <Container>
        <Title>Oops, something went wrong!</Title>
      </Container>
    );
  }

  return (
    <>
      <Title position="relative">
        {timeline?.title}
        {isCoach && !timeline?.isShared ? (
          <ShareButton
            onClick={handleShare}
            variant="cta"
            color="brandMain"
            disabled={isSharing}
          >
            Share Video
          </ShareButton>
        ) : null}
      </Title>
      <Grid gridTemplateColumns="1fr 30vw">
        {videoId ? (
          <VideoContainer>
            <YouTube
              onReady={(e) => {
                setPlayer(e.target);
                (window as any).player = e.target;
              }}
              videoId={videoId}
              opts={{
                enablejsapi: 1,
                origin: window.location,
              }}
            />
          </VideoContainer>
        ) : (
          <div>Video not available</div>
        )}
        <Timeline>
          <Wrapper>
            {timeline?.timeline
              .filter((e) => !Number.isNaN(e.second))
              .map((c) => (
                <Comment
                  key={c.second}
                  onClick={() => player?.seekTo(c.second, true)}
                >
                  <div className="second">{toTimeString(c.second)}</div>
                  <div className="content">
                    <strong>{c.behaviour}</strong>
                    {c.comment ? <span>{c.comment}</span> : null}
                  </div>
                </Comment>
              ))}
          </Wrapper>
        </Timeline>
      </Grid>
    </>
  );
}
