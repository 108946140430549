import { User } from "context/AuthContext";
import jwt, { JwtPayload } from "jwt-decode";

export const isTokenValid = (
  token: string,
  expiryThreshouldInSeconds = 90
): boolean => {
  const decoded: JwtPayload = jwt<JwtPayload>(token);
  if (decoded.exp) {
    const currentTime = Math.floor(Date.now() / 1000);
    return decoded.exp - currentTime > expiryThreshouldInSeconds;
  }
  return false;
};

export const decodeUser = (token: string) => {
  return jwt<User>(token);
};
