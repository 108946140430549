import { getBorder } from "@coachhubio/nova-borders";
import { getBorderColor, getColor } from "@coachhubio/nova-colors";
import {
  gap,
  margin,
  marginBottom,
  padding,
  paddingY,
} from "@coachhubio/nova-spaces";
import { Text } from "@coachhubio/nova-text";
import { getTextStyle } from "@coachhubio/nova-typography";
import { type CSSProperties } from "react";
import { Link as RRLink } from "react-router-dom";
import styled from "styled-components";

export function maxWidth() {
  return `
max-width: 100%;
@media(min-width: 576px) {
  max-width: 540px;
}
@media(min-width: 768px) {
  max-width: 720px;
}
@media(min-width: 992px) {
  max-width: 960px;
}
@media(min-width: 1200px) {
  max-width: 1140px;
}
  `;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  min-height: 100vh;
  background-color: white;
  ${getTextStyle("text", "base", "regular")}
`;

type GridProps = Pick<
  CSSProperties,
  "gap" | "gridTemplateColumns" | "gridTemplateRows"
>;
export const Grid = styled.div<GridProps>`
  display: grid;
  ${({ gap = "16px" }) => `gap: ${gap};`}
  ${({ gridTemplateColumns }) =>
    gridTemplateColumns
      ? `grid-template-columns: ${gridTemplateColumns};`
      : null}
  ${({ gridTemplateRows }) =>
    gridTemplateRows ? `grid-template-rows: ${gridTemplateRows};` : null}
`;

type RowProps = {
  justify?: CSSProperties["justifyContent"];
  align?: CSSProperties["alignItems"];
  gap?: CSSProperties["gap"];
};
export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: ${({ justify = "flex-start" }) => justify};
  ${({ align }) => (align ? `align-items: ${align};` : "")}
  ${({ gap = "16px" }) => `gap: ${gap};`}
`;

type ColumnProps = {
  justify?: CSSProperties["justifyContent"];
  align?: CSSProperties["alignItems"];
  gap?: CSSProperties["gap"];
};
export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  ${({ justify }) => (justify ? `justify-content: ${justify};` : "")}
  ${({ align }) => (align ? `align-items: ${align};` : "")}
  ${({ gap = 0 }) => `gap: ${gap};`}
`;

type TitleProps = Pick<CSSProperties, "position">;
export const Title = styled.h1<TitleProps>`
  display: flex;
  justify-content: space-between;

  ${getTextStyle("heading", "l", "bold")}
  ${getColor("primaryDark35")}
  ${margin("none")}
  ${({ position }) => (position ? `position: ${position};` : null)}
`;

export const SubTitle = styled.h2`
  ${getTextStyle("heading", "m", "bold")}
  ${getColor("primaryDark35")}
  ${margin("none")}
  ${marginBottom("xs")}
`;

export const Description = styled(Text)`
  ${marginBottom("s")}
`;

type ContainerProps = Pick<
  CSSProperties,
  "position" | "gridTemplateColumns" | "gridTemplateRows" | "gap"
>;
export const Container = styled.div<ContainerProps>`
  ${maxWidth()}
  margin: 0 auto;
  display: grid;
  ${gap("s")}
  ${padding("xl")}
  ${paddingY("m")}
${getBorderColor("neutral80")};

  &:first-child {
    border-top: none;
  }
  ${({ position }) => (position ? `position: ${position};` : null)}
  ${({ gridTemplateColumns }) =>
    gridTemplateColumns
      ? `grid-template-columns: ${gridTemplateColumns};`
      : null}
  ${({ gridTemplateRows }) =>
    gridTemplateRows ? `grid-template-rows: ${gridTemplateRows}` : null}
  ${({ gap }) => (gap ? `gap: ${gap}` : null)}
`;

export const Card = styled.div`
  width: 100%;

  ${padding("l")}
  ${getBorder({ color: "neutral80", width: "m", radius: "m" })}
`;

export const Link = styled(RRLink)`
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
  ${getColor("primaryDark25")};

  &:hover {
    text-decoration: underline;
  }
`;
