import AuthContext from "context/AuthContext";
import { useAuth0 } from "@auth0/auth0-react";

import { type ReactNode, useState, useEffect, useMemo } from "react";
import { useInterval } from "hooks/useInterval";
import { decodeUser, isTokenValid } from "utils/helper";
import { authenticate } from "services/auth.service";
import axios from "axios";
import config from "utils/config";

interface AuthProviderProps {
  children: ReactNode;
}

const sendAuthentication = authenticate(
  axios.create({
    baseURL: config.api.url,
  })
);

export default function AuthProvider({ children }: AuthProviderProps) {
  const [token, setToken] = useState<string>();
  const [refresh, setRefresh] = useState(0);
  const [error, setError] = useState<Error>();

  const user = useMemo(() => {
    if (!token) {
      return;
    }

    return decodeUser(token);
  }, [token]);

  const {
    isAuthenticated,
    isLoading,
    error: auth0Error,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();
  useEffect(() => {
    const tokenExchange = async (token: string | undefined) => {
      try {
        if (!token || !isTokenValid(token)) {
          const auth0Token = await getAccessTokenSilently();
          const resp = await sendAuthentication({
            grant_type: "token_exchange",
            subject_token: auth0Token,
          });
          if (resp?.access_token) {
            setToken(resp.access_token);
          }
        }
      } catch (err: any) {
        console.error(err);
        if (
          err.error === "login_required" ||
          err.error === "consent_required"
        ) {
          // avoid flickering of error page and don't set error
          loginWithRedirect();
        } else {
          setError(err);
        }
      }
    };

    if (isLoading || auth0Error || error) {
      return;
    }

    if (isAuthenticated) {
      tokenExchange(token);
    }
  }, [
    isAuthenticated,
    isLoading,
    auth0Error,
    error,
    getAccessTokenSilently,
    refresh,
    loginWithRedirect,
    token,
  ]);

  useInterval(() => {
    setRefresh(refresh + 1);
  }, 60 * 1000);

  return (
    <AuthContext.Provider value={{ user, token }}>
      {children}
    </AuthContext.Provider>
  );
}
