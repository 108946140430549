import { Outlet } from "react-router";
import { AppBar } from "./AppBar";
import { Wrapper } from "./Common";

type LayoutProps = {
  hideFooter?: boolean;
};

export default function Layout({ hideFooter }: LayoutProps) {
  return (
    <>
      <AppBar />
      <Wrapper>
        <Outlet />
      </Wrapper>
      {!hideFooter ? <footer /> : null}
    </>
  );
}
