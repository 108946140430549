import {
  backgroundColor,
  color,
  getBackgroundColor,
  getColor,
} from "@coachhubio/nova-colors";
import {
  marginBottom,
  paddingBottom,
  paddingTop,
} from "@coachhubio/nova-spaces";
import { Heading, Text } from "@coachhubio/nova-text";
import { Column, Container, Grid, Row, maxWidth } from "components/Common";
import { ProgramComponents } from "components/ProgramComponents";
import styled from "styled-components";

const Hero = styled.section`
  ${getBackgroundColor("brandDark")}
  ${paddingTop("5xl")}
  & > * {
    ${maxWidth()}
    margin: 0 auto;
  }
`;

const HeroImage = styled(Column)`
  max-width: 50%;
  & > img {
    width: 100%;
  }
`;

const Logos = styled.section`
  ${paddingTop("xl")}
  ${paddingBottom("xl")}
  margin: 0 auto;
  & > * {
    ${maxWidth()}
    img {
      width: 33%;
    }
  }
`;

const TextColumn = styled(Column)`
  & > :not(:last-child) {
    ${marginBottom("base")}
  }
`;

const Components = styled.section`
  ${getBackgroundColor("neutral95")}
  ${paddingTop("5xl")}
${paddingBottom("5xl")}
`;
const ComponentsContainer = styled.div`
  ${maxWidth()}
  margin: 0 auto;
  text-align: center;
  ${getColor("brandDark")}
`;

const Partners = styled.section`
  text-align: center;
  ${paddingTop("5xl")}
  ${paddingBottom("5xl")}
  ${Heading} {
    display: block;
    ${paddingBottom("xl")}
  }
  div {
    ${maxWidth()}
    margin: 0 auto;
    img {
      height: 96px;
    }
  }
`;

export default function ProgramInfo() {
  return (
    <>
      <Hero>
        <Row justify="space-between">
          <Column justify="center">
            <Heading color="neutral" modifier="bold" size="l">
              Welcome to Schneider Electric's Inclusion for Impact Program
              brought to you by CoachHub!
            </Heading>
          </Column>
          <HeroImage>
            <img src="/images/info-banner.png" />
          </HeroImage>
        </Row>
      </Hero>
      <Logos>
        <Column align="center">
          <img src="/images/coachhub-se.png" />
        </Column>
      </Logos>
      <Container>
        <Grid gap="64px" gridTemplateColumns="repeat(2, minmax(0, 1fr))">
          <Column justify="center">
            <img src="/images/training.png" />
          </Column>
          <TextColumn>
            <Text>
              Congratulations on your nomination to the{" "}
              <strong>Schneider Electric Inclusion for Impact program!</strong>{" "}
              It's our pleasure to welcome you and guide you along your learning
              journey!
            </Text>
            <Text>
              This four-month training will offer you a unique learning
              experience. You will receive unlimited individual coaching, attend
              two inclusion workshops, participate in two interactive business
              simulations and have access to customized e-learning content. To
              conclude the program, you will participate in a coaching circle to
              reflect on your learnings.
            </Text>
            <Text>
              This page is designed to be your one source of information for all
              components of the Inclusion for Impact program. Please find
              detailed descriptions and resources of each program component
              below.
            </Text>
            <Text>We are looking forward to working with you!</Text>
          </TextColumn>
        </Grid>
      </Container>
      <Components>
        <ComponentsContainer>
          <Column gap="16px">
            <Heading size="xl">Inclusion For Impact Program Components</Heading>
            <Text size="l" modifier="regular">
              Click on each of the components to learn more
            </Text>
          </Column>
          <ProgramComponents />
        </ComponentsContainer>
      </Components>
      <Partners>
        <Heading color="brandDark" size="2xl">
          Our Partners
        </Heading>
        <Row justify="center" gap="64px">
          <img src="/images/profitability.png" />
          <img src="/images/ba.png" />
        </Row>
      </Partners>
    </>
  );
}
