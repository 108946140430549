import React from "react";

export type AuthType = "sessionStorage"; // | "cookier"

export const UserRoles = {
  Coach: "COACH",
  Coachee: "COACHEE",
} as const;

export type UserRole = typeof UserRoles[keyof typeof UserRoles];
export interface User {
  email: string;
  firstName: string;
  lastName: string;
  roles: UserRole[];
}

export interface AuthContextInterface {
  user?: User;
  token?: string;
}

const AuthContext = React.createContext<AuthContextInterface | null>(null);
export const AuthContextConsumer = AuthContext.Consumer;
export default AuthContext;
