import useAuth from "hooks/useAuth";
import { createContext, ReactNode, useEffect, useMemo, useRef } from "react";
import { retryPromiseWithDelay } from "utils/api";
import { APIClient } from "./client";

export type Event = {
  userId: string;
  firstName?: string;
  lastName?: string;
  events: {
    eventId: number;
    title: string;
    video: string;
    startAt: string;
    isShared: boolean;
  }[];
};

export interface IEventService {
  listEvents(): Promise<Event[]>;
  shareVideo(eventId: number, coacheeId: string): Promise<void>;
  getTimeline(eventId: number, coachedId: string): Promise<EventWithTimeline>;
}

export type EventWithTimeline = {
  id: number;
  coacheeId: string;
  title: string;
  video: string | null;
  isShared: boolean;
  timeline: {
    second: number;
    behaviour: string;
    comment: string | null;
  }[];
};

export const EventServiceContext = createContext<IEventService | undefined>(
  undefined
);

interface EventServiceProps {
  children: ReactNode;
}
const EventService = ({ children }: EventServiceProps) => {
  const { token: tokenValue } = useAuth();
  const token = useRef<string>();
  useEffect(() => {
    token.current = tokenValue;
  }, [tokenValue]);

  const service: IEventService = useMemo(() => {
    console.log(`EventService: Creating new APIClient`);
    const client = new APIClient(token);

    const service: IEventService = {
      async listEvents(): Promise<Event[]> {
        try {
          const res = await retryPromiseWithDelay(() =>
            client.get<Event[]>("/event", { authentication: true })
          );
          // const res = await client.get<Event[]>("/event", { authentication: true });
          return res.data;
        } catch (err) {
          console.error(`Failed to fetch events: ${err}`);
          return [];
        }
      },

      async shareVideo(eventId: number, coacheeId: string) {
        await retryPromiseWithDelay(() =>
          client.post<{}>(`/event/${eventId}/share`, {
            coachee_id: coacheeId,
          })
        );
      },

      async getTimeline(
        eventId: number,
        coacheeId: string
      ): Promise<EventWithTimeline> {
        try {
          const res = await retryPromiseWithDelay(() =>
            client.get<EventWithTimeline>(
              `/event/${eventId}/${coacheeId}/timeline`,
              { authentication: true }
            )
          );
          return res.data;
        } catch (err) {
          console.log(`Failed to fetch timeline: ${err}`);
          throw err;
        }
      },
    };
    return service;
  }, [token]);

  return (
    <EventServiceContext.Provider value={service}>
      {children}
    </EventServiceContext.Provider>
  );
};

export default EventService;
