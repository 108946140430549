export function waitFor(secs: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, secs * 1000);
  });
}

export async function retryPromiseWithDelay<TResult>(
  cb: () => Promise<TResult>,
  attempt: number = 3,
  delay: number = 1
): Promise<TResult> {
  try {
    const res = await cb();
    return res;
  } catch (e) {
    if (attempt <= 0) {
      return Promise.reject(e);
    }
    console.log("retrying", attempt);
    await waitFor(delay);
    return retryPromiseWithDelay(cb, attempt - 1, delay);
  }
}
