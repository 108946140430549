import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles } from "@coachhubio/ui-components";

interface FrameWrapperProps extends React.ComponentPropsWithoutRef<"section"> {
  children: React.ReactNode;
  className?: string;
  withBorder?: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    frameWrapper: {
      border: 0,
      boxSizing: "border-box",
      borderRadius: 7,
      minHeight: "100%",
    },
    withBorder: {
      border: "1px solid #E3E3E3",
    },
  })
);

export function FrameWrapper({
  children,
  className = "",
  withBorder = false,
  ...props
}: FrameWrapperProps) {
  const classes = useStyles({});
  return (
    <section
      className={clsx(classes.frameWrapper, className, {
        [classes.withBorder]: withBorder,
      })}
      {...props}
    >
      {children}
    </section>
  );
}
