import { useContext } from "react";
import { EventServiceContext } from "services/events.service";

export default function useEventService() {
  const context = useContext(EventServiceContext);
  if (!context) {
    throw new Error("EventService is missing.");
  }

  return context;
}
