import { Avatar } from "@coachhubio/nova-avatar";
import {
  AppBar as CoachhubAppBar,
  makeStyles,
} from "@coachhubio/ui-components";
import { Toolbar } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import coachhubLogo from "../assets/coachhub-logo.svg";
import { NavLink } from "react-router-dom";
import useAuth from "hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(10),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    justifyContent: "center",
  },
  novaWrapper: {
    zIndex: 10,
    position: "sticky",
    top: 0,
  },
  toolbar: {
    maxWidth: 1332,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    padding: "0 1rem",
    display: "flex",
    gap: "60px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  link: {
    ...theme.styleguide.typography.header,
    whiteSpace: "nowrap",
    "&:hover": {
      textDecoration: "none",
    },
  },
  wrapperButton: {
    height: "100%",
  },
  menuLinks: {
    display: "flex",
    gap: "60px",
    flex: 1,
    justifyContent: "flex-end",
  },
  logoAndSwitchContainer: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    lineHeight: 0,
  },
}));

export function AppBar() {
  const { t } = useTranslation("AppBar");
  const { colorPrimary, ...classes } = useStyles();
  const { user, isAuthenticated, logout } = useAuth();

  return (
    <div className={classes.root}>
      <CoachhubAppBar
        classes={{ root: classes.appBar }}
        color="inherit"
        position="fixed"
      >
        <Toolbar classes={{ root: classes.toolbar }}>
          <div className={classes.logoAndSwitchContainer}>
            <NavLink className={classes.logo} to="/" data-testid="logo">
              <img src={coachhubLogo} alt="Logo" />
            </NavLink>
          </div>
          <div className={classes.menuLinks}>
            <NavLink className={classes.link} to="/" data-testid="info">
              Home
            </NavLink>
            {isAuthenticated ? (
              <NavLink className={classes.link} to="/info" data-testid="info">
                Program Info
              </NavLink>
            ) : null}
            <NavLink
              className={classes.link}
              to="/login"
              data-testid="logout"
              onClick={(e) => {
                e.preventDefault();
                logout({
                  logoutParams: {
                    returnTo: window.location.origin,
                  },
                });
              }}
            >
              {t("logout")}
            </NavLink>
          </div>
          {user ? (
            <Avatar firstName={user.firstName} lastName={user.lastName} />
          ) : null}
        </Toolbar>
      </CoachhubAppBar>
    </div>
  );
}
