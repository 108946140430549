import { Routes, Route } from "react-router-dom";

// Components
import "./App.css";
import Events from "screens/Events";
import ProtectedRoute from "components/PrivateRoute";
import Layout from "components/Layout";
import Recording from "screens/Recording";
import EventService from "services/events.service";
import ProgramInfo from "screens/ProgramInfo";

function App() {
  return (
    <EventService>
      <Routes>
        <Route element={<Layout />}>
          <Route element={<ProtectedRoute isAllowed />}>
            <Route path="/" element={<Events />} />
            <Route path="/recording" element={<Recording />} />
            <Route path="/info" element={<ProgramInfo />} />
          </Route>
        </Route>
      </Routes>
    </EventService>
  );
}

export default App;
