import { Card, Column, Description, Link } from "components/Common";
import { Event } from "services/events.service";

type CoacheeEventsProps = {
  userId?: Event["userId"];
  events: Event["events"][0][];
};

export default function CoacheeEvents({ userId, events }: CoacheeEventsProps) {
  return (
    <>
      <Description>
        Find below the simulation recordings and observations from your
        participations:
      </Description>
      <Column gap="1rem">
        {events.length > 0 ? (
          events.map((e) => (
            <Link
              key={e.eventId}
              to={`/recording?event_id=${e.eventId}&coachee_id=${userId}`}
            >
              <Card>{e.title}</Card>
            </Link>
          ))
        ) : (
          <Card>No recording to show</Card>
        )}
      </Column>
    </>
  );
}
