import { Accordion } from "@coachhubio/nova-accordion";
import { BookmarkFullIcon, BookmarkIcon } from "@coachhubio/nova-icon";
import { Card, Column, Description, Link, Row } from "components/Common";
import { Event } from "services/events.service";

type CoachEventsProps = {
  events: Event[];
};

function getPartecipantName(e: Event): string {
  if (e.firstName || e.lastName) {
    return `${e.firstName ?? ""} ${e.lastName ?? ""}`;
  }
  return "unknown";
}

function EmptyList() {
  return <Card>Oops, no coachee found.</Card>;
}

function EventList({ events }: { events: Event[] }) {
  return (
    <>
      {events.map((coachee) => (
        <Accordion
          key={coachee.userId}
          title={getPartecipantName(coachee)}
          chevronPosition="start"
        >
          <EventRow coachee={coachee} />
        </Accordion>
      ))}
    </>
  );
}

function EventRow({ coachee }: { coachee: Event }) {
  return (
    <>
      {coachee.events.map((e) => (
        <Link
          key={e.eventId}
          to={`/recording?event_id=${e.eventId}&coachee_id=${coachee.userId}`}
        >
          <Row gap="8px">
            {e.isShared ? (
              <BookmarkFullIcon size="m" color="brandMain" />
            ) : (
              <BookmarkIcon size="m" color="brandMain" />
            )}
            <span>{e.title}</span>
          </Row>
        </Link>
      ))}
    </>
  );
}

export default function CoachEvents({ events }: CoachEventsProps) {
  return (
    <>
      <Description>
        Find below the cohorts in which your coachees have partecipated:
      </Description>
      <Column gap="1rem">
        {events?.length > 0 ? <EventList events={events} /> : <EmptyList />}
      </Column>
    </>
  );
}
