import { useEffect, ReactElement } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, Outlet } from "react-router";
import { LoadingState } from "./LoadingState";

interface ProtectedRouteProps {
  isAllowed: boolean;
  redirectPath?: string;
  children?: ReactElement;
}

export default function ProtectedRoute({
  isAllowed,
  redirectPath = "/",
  children,
}: ProtectedRouteProps): JSX.Element {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  useEffect(() => {
    if (isAuthenticated || isLoading) {
      return;
    }

    const handleLoginWithRedirect = async () => {
      await loginWithRedirect({
        appState: {
          returnTo: `${window.location.pathname}${window.location.search}`,
        },
      });
    };
    handleLoginWithRedirect();
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  if (!isAuthenticated) {
    return <LoadingState />;
  }

  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ?? <Outlet />;
}
