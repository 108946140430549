import {
  Accordion,
  AccordionContent,
  AccordionProps,
} from "@coachhubio/nova-accordion";
import { Link } from "@coachhubio/nova-link";
import { Column, Grid } from "./Common";
import styled from "styled-components";
import { getFontSizeValue } from "@coachhubio/nova-typography";
import { useState } from "react";

const ComponentGrid = styled(Grid)`
  text-align: left;
  padding: 32px 0;
`;

const StyledAccordion = styled(Accordion)`
  ${AccordionContent} {
    font-size: ${getFontSizeValue("text", "s")};
    line-height: ${getFontSizeValue("text", "s")};
    ${Link} {
      font-size: ${getFontSizeValue("text", "s")};
      line-height: ${getFontSizeValue("text", "s")};
    }
    p {
      margin: 0 0 8px 0;
    }
  }
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
`;
const ListItem = styled.li``;

const images = [
  "key-resources.png",
  "individual-coaching.png",
  "workshops.jpeg",
  "business-simulations.png",
  "key-resources.png",
];
const accordions = 5;
export function ProgramComponents() {
  const [expandedArr, setExpandedArr] = useState<boolean[]>(
    Array(accordions).fill(false)
  );
  const [currentImage, setCurrentImage] = useState(0);

  function handleAccordionClick(i: number) {
    return function () {
      setExpandedArr(
        Array(accordions)
          .fill(false)
          .map((_, idx) => idx === i)
      );
      setCurrentImage(i);
    };
  }
  return (
    <ComponentGrid gridTemplateColumns="repeat(2, minmax(0, 1fr))">
      <Column>
        <div onClick={handleAccordionClick(0)}>
          <StyledAccordion
            variant="borderless"
            title="Schneider Electric Key Resources"
            isExpanded={expandedArr[0]}
          >
            <List>
              <ListItem>
                <Link
                  href="https://softr-assets-eu-prod.s3.eu-central-1.amazonaws.com/applications/d53b9af2-a4cd-4d2f-a72f-202dd357ab95/assets/19ab2f94-0eac-4fbc-84bb-7d53ca77e01a.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  SE Business case for the program
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="https://schneiderelectric.sharepoint.com/:b:/s/NAMLearningSolutionsTeam/EQMZZrw7hghOs7ybv2fLxkABBjIC71hwxaSkLSUwQlY32g?e=VffRCq"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Aamir Paul's video
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="https://schneiderelectric.sharepoint.com/:b:/s/NAMLearningSolutionsTeam/EQMZZrw7hghOs7ybv2fLxkABBjIC71hwxaSkLSUwQlY32g?e=VffRCq"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  SE Manager Guide
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="https://www.se.com/ww/en/download/document/SchneiderElectric_TrustCharter/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  SE Trust Charter and Guiding Principles
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="https://download.schneider-electric.com/files?p_Doc_Ref=Diversity_Inclusion_Policy&amp;_ga=2.111197636.1119157263.1688568566-960626675.1663355962"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  SE DEI policy
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  href="https://schneider-electric-ifi.coachhub.com/program-completion-criteria"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  IFI Program Completion Criteria
                </Link>
              </ListItem>
            </List>
          </StyledAccordion>
        </div>
        <div onClick={handleAccordionClick(1)}>
          <StyledAccordion
            variant="borderless"
            title="Individual Coaching and E-learning Content"
            isExpanded={expandedArr[1]}
          >
            <p>
              CoachHub is the global leader in digital coaching. We enable
              behavioral change with world-class coaching delivered through our
              state-of-the-art digital platform.
              <br />
              CoachHub is the solution to develop all employees into highly
              effective, inspiring leaders.
            </p>
            <p>
              In the Inclusion for Impact program, you will enjoy personalized
              coaching with a highly-qualified business coach. We recommend that
              you meet with your coach at least twice per month. Topics to
              discuss with your coach include: your pre-program assessment,
              learnings from the workshops and business simulations, and any
              other leadership topic you are facing.
            </p>
            <List>
              <ListItem>
                <Link href="https://www.youtube.com/watch?v=qKBINPBqR1M">
                  Introducing CoachHub Video
                </Link>
              </ListItem>
              <ListItem>
                <Link href="https://resources.coachhub.io/start-your-coaching-adventure">
                  CoachHub Onboarding Page
                </Link>
              </ListItem>
              <ListItem>
                <Link href="https://softr-assets-eu-prod.s3.eu-central-1.amazonaws.com/applications/d53b9af2-a4cd-4d2f-a72f-202dd357ab95/assets/5ca3aefc-9e54-44f8-ba6e-0c6ca629995e.pdf">
                  Coachee Orientation Guide
                </Link>
              </ListItem>
              <ListItem>
                <Link href="https://coachhub-schneider-electric-ilp.softr.app/technical-trouble-shooting">
                  Technical FAQ
                </Link>
              </ListItem>
            </List>
            <p>
              <strong>
                Introducing CoachHub Video CoachHub Onboarding Page
              </strong>
              <br />
              Click <Link href="https://app.coachhub.com">here</Link> to login
              to the CoachHub platform using your SESA email address.
            </p>
            <p>
              To supplement your individual coaching, you have access to six
              E-learning collections focusing on inclusive leadership. This
              valuable content can be found in the Academy section of the
              CoachHub platform
            </p>
            <p>E-learning collections will be available starting Aug. 28.</p>
          </StyledAccordion>
        </div>
        <div onClick={handleAccordionClick(2)}>
          <StyledAccordion
            variant="borderless"
            title="Workshops"
            isExpanded={expandedArr[2]}
          >
            <p>
              The Inclusion for Impact workshops are conducted by our partner,
              Bossert Associates, which is a global leadership development
              company committed to developing free leaders and coaches, those of
              today and tomorrow. For more than 7 years, BA has been designing
              and shaping leadership and coaching with their digital and
              in-person programs in more than 50 countries and 40 companies.
              Through their work with more than 300 alumni, the BA Coaching
              Academy has developed professional coaching talent since 2015. Our
              methods and trainings are certified by the ICF, International
              Coach Federation, the global gold standard for leadership and
              coaching.
            </p>
            <p>
              <strong>Workshop 1 - Interrupting Bias</strong>
              <br />
              Creating a culture of inclusion and achieving high performance
              requires addressing bias. Only by interrupting bias can we create
              an environment where individuals can bring their full capabilities
              to the table. However, interrupting bias is not easy, as it has
              proven to be a formidable opponent.
              <br />
              In this workshop, we will provide a firsthand experience of the
              effects of bias and work with you to develop a personal approach
              to interrupting bias. We believe this approach will be invaluable
              in promoting a more inclusive workplace where everyone can thrive.
              <br />
              <strong>
                Interrupting Bias Workshop Dates: Aug. 21 - Sept. 28, 2023
              </strong>
            </p>
            <p>
              <strong>Workshop 2 – Pragmatic Inclusive Leadership</strong>
              <br />
              This workshop will introduce practical methods for promoting
              inclusion as a leader. You will learn about the different elements
              of inclusive leadership, practice them with your colleagues, and
              discuss how to apply them in your business environment. These
              insights will enable you to work on the most relevant aspects of
              inclusive leadership with your coach.
              <br />
              By the end of the workshop, you will have a deeper understanding
              of the importance of inclusion and the practical tools necessary
              to lead inclusively and effectively.
              <br />
              <strong>
                Pragmatic Inclusive Leadership Workshop Dates: Oct. 23 - Dec. 8,
                2023
              </strong>
            </p>
            <p>
              <strong>
                To access your notebook and pre-reads, please click{" "}
                <Link href="https://go.bossert-associates.com/se-ilp-pragmatic-inclusive-leadership-materials">
                  here
                </Link>
                .
              </strong>
            </p>
          </StyledAccordion>
        </div>
        <div onClick={handleAccordionClick(3)}>
          <StyledAccordion
            variant="borderless"
            title="Business Simulations"
            isExpanded={expandedArr[3]}
          >
            <p>
              The Business Simulations in Inclusion for Impact are conducted by
              our partner, Profitability Business Simulations.
              <br />
              PBS work with the world’s leading organizations who need to
              increase the speed to effectiveness of their people, at scale,
              while delivering an outstanding and differentiated employee
              experience which drives talent development and retention.
            </p>
            <p>
              PBS design and deliver online and in-person game-based learning
              experiences that connect, engage and upskill people in far more
              impactful, memorable and relevant ways than traditional learning
              methods.
            </p>
            <p>
              PBS' unique, social and competitive training format allows
              learners to discover in just a few hours what might take years in
              their real jobs. They are then equipped to perform back at work.
            </p>

            <p>
              <strong>Talent Game Dates: Sept. 5 - Oct. 12, 2023</strong>
              <br />
              <Link href="https://softr-assets-eu-prod.s3.eu-central-1.amazonaws.com/applications/d53b9af2-a4cd-4d2f-a72f-202dd357ab95/assets/d462cdf1-636e-430a-8d66-9831f3e945d6.pdf">
                Pre-read material for Talent Game
              </Link>
            </p>

            <p>
              <strong>Mosaic Game Dates: Oct. 17 - Nov. 16, 2023</strong>
              <br />
              <Link href="https://softr-assets-eu-prod.s3.eu-central-1.amazonaws.com/applications/d53b9af2-a4cd-4d2f-a72f-202dd357ab95/assets/fee9ca40-b92e-4741-add5-4c21ce248f7e.pdf">
                Pre-read material for Mosaic Game
              </Link>
            </p>
          </StyledAccordion>
        </div>
        <div onClick={handleAccordionClick(4)}>
          <StyledAccordion
            variant="borderless"
            title="Coaching Circles"
            isExpanded={expandedArr[4]}
          >
            <p>
              Coaching Circles are a final opportunity to reflect on your
              learnings of the Inclusion for Impact program. The Coaching Circle
              is a trusted, confidential, peer-to-peer learning environment,
              facilitated by a certified CoachHub coach, with approximately 12
              Schneider Inclusion for Impact participants.
            </p>
            <p>
              Discussions and conversations are designed to offer a space for
              peer reflection and to amplify learnings in an insightful manner
              that is beneficial for the group
            </p>
            <p>
              The circle creates a group bond for driving accountability and
              sustaining learnings and actions through peer collaboration
            </p>
            <p>
              <strong>Coaching Circle Dates:</strong>
              <br />
              <strong>Monday - Thursday, Nov. 27 - Dec. 14</strong>
              <br />
              <strong>Times: 2- 3 p.m. EST or 3-4 p.m. EST</strong>
            </p>
          </StyledAccordion>
        </div>
      </Column>
      <Column>
        <img src={`/images/${images[currentImage]}`} />
      </Column>
    </ComponentGrid>
  );
}
