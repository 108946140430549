import { type Event } from "services/events.service";
import { Title } from "components/Common";
import { LoadingState } from "components/LoadingState";
import useAuth from "hooks/useAuth";
import { useEffect, useMemo, useState } from "react";
import { UserRoles, type UserRole } from "context/AuthContext";
import CoacheeEvents from "./CoacheeEvents";
import CoachEvents from "./CoachEvents";
import useEventService from "hooks/useEventService";

const roles = Object.values(UserRoles);

function toCamelCase(str: string) {
  return str
    .split(" ")
    .map(
      (word: string) =>
        `${word.slice(0, 1).toUpperCase()}${word.slice(1).toLowerCase()}`
    )
    .join(" ");
}

function renderEventsByRole(role: UserRole | undefined, events: Event[] = []) {
  switch (role) {
    case "COACH":
      return <CoachEvents events={events} />;
    case "COACHEE":
      return (
        <CoacheeEvents
          userId={events[0]?.userId}
          events={events[0]?.events ?? []}
        />
      );
    default:
      return null;
  }
}

export default function Events() {
  const { user } = useAuth();
  const { listEvents } = useEventService();
  const [events, setEvents] = useState<Event[]>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    let stale = false;
    console.log(`Events.useEffect: calling fetchEvents`);
    async function fetchEvents() {
      setIsLoading(true);
      const events = await listEvents();
      if (!stale) {
        setEvents(events);
      }
      setIsLoading(false);
    }
    fetchEvents();
    return () => {
      stale = true;
    };
  }, [listEvents]);

  const [name, role] = useMemo(() => {
    const role = user?.roles?.find((r) => roles.includes(r));
    const name = user?.firstName ?? toCamelCase(role ?? "");
    return [name, role];
  }, [user]);

  return (
    <>
      <Title>Hello {name}!</Title>
      {isLoading ? <LoadingState /> : renderEventsByRole(role, events)}
    </>
  );
}
