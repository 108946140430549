import { useAuth0 } from "@auth0/auth0-react";
import AuthContext from "context/AuthContext";
import { useContext } from "react";

export default function useAuth() {
  const { isAuthenticated, isLoading, logout } = useAuth0();
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("AuthProvider is missing.");
  }

  return {
    isAuthenticated,
    isLoading,
    logout,
    ...context,
  };
}
