import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@coachhubio/ui-components";
import AuthProvider from "providers/AuthProvider";
import Auth0ProviderWithNavigate from "providers/Auth0ProviderWithNavigate";

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_MOCK_API === "true"
) {
  const { worker } = require("./mocks/browser");
  worker.start();
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider>
        <>
          <CssBaseline />
          <Auth0ProviderWithNavigate>
            <AuthProvider>
              <App />
            </AuthProvider>
          </Auth0ProviderWithNavigate>
        </>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
