export type Config = typeof config;

const config = {
  api: {
    url: process.env.REACT_APP_MOCK_API
      ? ""
      : process.env.REACT_APP_API_URL ?? "",
  },
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN ?? "",
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? "",
    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    redirectUri:
      process.env.REACT_APP_AUTH0_CALLBACK_URL ??
      `${window.location.host}/callback`,
  },
  cookies: {
    accessToken: process.env.REACT_APP_COOKIE_TOKEN ?? "coachhub-access-token",
    user: process.env.REACT_APP_COOKIE_USER ?? "coachhub-user",
  },
};
export default config;
