import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  CreateAxiosDefaults,
} from "axios";
import { MutableRefObject } from "react";
import config from "utils/config";

function createAxiosClient() {
  const options: CreateAxiosDefaults = {
    baseURL: config.api.url,
    headers: {
      post: {
        "Content-Type": "application/json; charset=utf-8",
      },
      common: {
        Accept: "application/json",
      },
    },
    withCredentials: false,
  };

  return axios.create(options);
}

export class APIClient {
  private readonly client: AxiosInstance;
  constructor(private readonly token: MutableRefObject<string | undefined>) {
    this.client = createAxiosClient();
  }

  private enhanceConfig(config?: AxiosRequestConfig) {
    if (!this.token.current) {
      return config;
    }

    return {
      ...config,
      headers: {
        Authorization: `Bearer ${this.token.current}`,
        ...config?.headers,
      },
    };
  }

  get<T>(url: string, config?: AxiosRequestConfig) {
    return this.client.get<T>(url, this.enhanceConfig(config));
  }

  post<TResult, TData = any>(
    url: string,
    data: TData,
    config?: AxiosRequestConfig
  ) {
    return this.client.post<TResult>(url, data, this.enhanceConfig(config));
  }
}
